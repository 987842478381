<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="table"
    />

    <div v-else>
      <div class="pa-0 px-1 pb-3 w-full d-flex align-center justify-end">
        <v-dialog
          v-model="isCreating"
          width="500"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              small
              color="primary"
              :ripple="false"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              新規{{ currentClub.referralAlias }}
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model="addTarget.name"
                label="名前"
              />
              <v-switch
                v-model="addTarget.isVendingContributionTrigger"
                :append-icon="icons.mdiCashMarker"
                style="width: 70px"
              ></v-switch>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :ripple="false"
                @click="isCreating = false; create()"
              >
                作成
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :ripple="false"
                @click="isCreating = false"
              >
                キャンセル
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <v-data-table
        :key="JSON.stringify(referrals)"
        v-sortable-data-table
        :headers="headers"
        :items="referrals"
        :header-props="{ sortByText: 'ソート' }"
        hide-default-footer
        disable-pagination
        no-data-text="データがありません"
        @sorted="updatePositions($event)"
      >
        <template #[`item.attributes.name`]="{item}">
          <yona-edit-dialog
            :display-name="item.attributes.name"
            @open="edit(item)"
            @save="update(item)"
          >
            <template #input>
              <v-text-field
                v-model="editTarget.name"
                placeholder="名前"
              />
            </template>
          </yona-edit-dialog>
        </template>

        <template #[`item.attributes.isVendingContributionTrigger`]="{item}">
          <v-switch
            v-model="item.attributes.isVendingContributionTrigger"
            :append-icon="icons.mdiCashMarker"
            dense
            style="width: 70px"
            @click="updateTrigger(item)"
          ></v-switch>
        </template>

        <template #[`item.actions`]="{item}">
          <yona-edit-dialog
            title="削除の確認"
            btn-color="error"
            save-text="同意の上で削除"
            @save="destroy(item.id)"
          >
            <template #append-outer-display-name>
              <v-btn
                icon
                :ripple="false"
              >
                <v-icon small>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>

            <template #input>
              <span>
                削除を実行してもよろしいですか
              </span>
            </template>
          </yona-edit-dialog>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  mdiTrashCanOutline,
  mdiPlus,
  mdiCashMarker,
} from '@mdi/js'
import { map } from 'lodash'
import sortableDataTable from '@/directives/sortableDataTable'
import ReferralApi from '@/api/waiter/Referral'
import useCurrentData from '@/views/composable/useCurrentData'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'

export default {
  components: {
    YonaEditDialog,
  },
  directives: {
    sortableDataTable,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { currentClub } = useCurrentData()

    const isLoading = ref(false)
    const isCreating = ref(false)
    const isEditing = ref(false)
    const isDeleting = ref(false)
    const referrals = ref([])
    const editTarget = reactive({ id: null, name: null })
    const addTarget = reactive({ name: null, isVendingContributionTrigger: null })

    const edit = item => {
      isEditing.value = true
      editTarget.id = item.id
      editTarget.name = item.attributes.name
    }

    const create = async () => {
      const { name, isVendingContributionTrigger } = addTarget
      const res = await ReferralApi.createReferral({ name, isVendingContributionTrigger })

      if (res?.data?.data) {
        referrals.value.push(res.data.data)
        vm.$toast.success(`${currentClub.value.referralAlias}を作成しました`)
      }

      addTarget.name = null
      addTarget.isVendingContributionTrigger = null
    }

    const update = async item => {
      const { id, name } = editTarget
      const { isVendingContributionTrigger } = item.attributes
      const res = await ReferralApi.updateReferral({
        id,
        name,
        isVendingContributionTrigger,
      })

      if (res?.data?.data) {
        const index = referrals.value.findIndex(o => +o.id === +id)
        referrals.value.splice(index, 1, res.data.data)
        vm.$toast.success(`${currentClub.value.referralAlias}を更新しました`)
      }

      isEditing.value = false
      editTarget.id = null
      editTarget.name = null
    }

    const updateTrigger = async item => {
      const { id } = item
      const { name, isVendingContributionTrigger } = item.attributes

      const res = await ReferralApi.updateReferral({
        id,
        name,
        isVendingContributionTrigger,
      })

      if (res?.data?.data) {
        const index = referrals.value.findIndex(o => +o.id === +id)
        referrals.value.splice(index, 1, res.data.data)
        vm.$toast.success(`${currentClub.value.referralAlias}を更新しました`)
      }
    }

    const destroy = async id => {
      const res = await ReferralApi.deleteReferral(id)

      if (res) {
        const index = referrals.value.findIndex(o => +o.id === +id)
        referrals.value.splice(index, 1)
        vm.$toast.success(`${currentClub.value.referralAlias}を削除しました`)
      }

      isDeleting.value = false
    }

    const getReceipts = async () => {
      isLoading.value = true
      const res = await ReferralApi.getReferrals()
      if (res?.data) {
        referrals.value = res.data.referrals.data
      }
      isLoading.value = false
    }

    const updatePositions = async event => {
      const movedReferral = referrals.value.splice(event.oldIndex, 1)[0]
      referrals.value.splice(event.newIndex, 0, movedReferral)
      const idAndPositions = map(referrals.value, (o, idx) => {
        return { id: o.id, position: idx }
      })

      const res = await ReferralApi.updatePositions(idAndPositions)
    }

    getReceipts()

    return {
      // data
      referrals,
      isLoading,
      isCreating,
      isEditing,
      isDeleting,
      headers: [
        { text: '名前', value: 'attributes.name' },
        { text: '', value: 'attributes.isVendingContributionTrigger' },
        { text: '', value: 'actions', align: 'end' },
      ],
      editTarget,
      addTarget,

      // methods
      create,
      edit,
      update,
      updateTrigger,
      destroy,
      updatePositions,

      icons: {
        mdiTrashCanOutline,
        mdiPlus,
        mdiCashMarker,
      },
    }
  },
}
</script>
